import React, { useState, useEffect } from 'react';
import './creatives.scss';
import { PhotoAlbum } from "react-photo-album";
// import photos from "./photos";
import SelectCustom from '../../components/selectCustom/SelectCustom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Cookies from 'js-cookie';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import config from '../../config';

const fetchUserData = async (activeUserId, activePage, selectedSort, total) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/users/${activeUserId}/creatives`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const skip = activePage * 20 - 20;
    const params = {
        skip: skip,
        limit: 1000,
        sort: JSON.stringify(selectedSort)
    }
    const response = await axios.get(url, { headers, params });
    return response.data;
};

const fetchStatData = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/users/${activeUserId}/creatives-statistic`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
};

function Creatives({ activeUserId }) {
    const [activePage, setActivePage] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [imgModal, setImgModal] = React.useState('');
    const [unsplashPhotos, setUnsplashPhotos] = useState([]);
    const handleOpen = (img) => {

        setImgModal(img);
        setOpen(true);

    };
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 475,
    };

    const sorts = [
        { value: 'createdAt', label: 'Created Date' },
        // { value: 'price', label: 'Price' },
        // { value: 'oldPrice', label: 'Old Price' },
        // { value: 'title', label: 'Title' },
    ];

    const defaultValue = { value: 'createdAt', label: 'Created Date' };

    const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: "desc" });

    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value == 'price' || selectedOption.value == 'oldPrice' || selectedOption.value == 'title') {
            setSelectedSort({ [selectedOption.value]: "asc" })
        } else {
            setSelectedSort({ [selectedOption.value]: "desc" })
        }
    };

    const [total, setTotal] = useState('0');
    const [custom, setCustom] = useState('0');
    const [aiGen, setAiGen] = useState('0');

    // function getStats() {
    //     const url = `https://staging.zeely.link/admins/users/${activeUserId}/creatives-statistic`;

    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${Cookies.get('authToken')}`,
    //     };

    //     axios.get(url, { headers })
    //         .then(response => {
    //             setTotal(response.data.total);
    //             if (response.data.total == 0) {
    //                 setUnsplashPhotos([]);
    //             }
    //             setCustom(response.data.custom)
    //             setAiGen(response.data.aIGenerated)
    //         })
    //         .catch(error => {

    //         });
    // }


    // function getUsers() {

    //     const url = `https://staging.zeely.link/admins/users/${activeUserId}/creatives`;

    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${Cookies.get('authToken')}`,
    //     };

    //     const skip = activePage * 20 - 20;
    //     const params = {
    //         skip: skip,
    //         limit: total,
    //         sort: JSON.stringify(selectedSort)
    //     }

    //     axios.get(url, { headers, params })
    //         .then(response => {
    //             console.log(response)


    //             const data = response.data.items
    //                 .filter(image => !image.isDeleted)
    //                 .map(image => {
    //                     const inputDateString = image.createdAt;
    //                     const inputDate = new Date(inputDateString);
    //                     const formattedDate = `${inputDate.getDate()}.${(inputDate.getMonth() + 1).toString().padStart(2, '0')}.${inputDate.getFullYear()}`;

    //                     let platform = '';
    //                     let format = '';

    //                     if(image.creativeImages[0].creativeTheme) {
    //                         platform = image.creativeImages[0].creativeTheme.creativeFormat.platform;
    //                         format = image.creativeImages[0].creativeTheme.creativeFormat.format;
    //                     }
    //                     return {
    //                         src: image.creativeImages[0].url,
    //                         width: image.creativeImages[0].width,
    //                         height: image.creativeImages[0].height,
    //                         social: `${platform} ${format}`,
    //                         title: image.data.title,
    //                         date: formattedDate,
    //                         ai: image.isAIGenerated
    //                     };
    //                 });
    //             setUnsplashPhotos(data);
    //         })
    //         .catch(error => {

    //         });
    // }

    const unsplashLoading = [
        { id: "8gVv6nxq6gY", width: 1080, height: 1350, social: 'Facebook Ads', title: 'Delicious wedding catering services for your special day in NYC', date: '12.10.2023', img: 'g1.jpg', ai: false },
        { id: "KG3TyFi0iTU", width: 1080, height: 1920, social: 'Instagram Stories', title: 'Delicious wedding catering services for your special day in NYC', date: '12.10.2023', img: 'g5.jpg', ai: false },
        { id: "Dhmn6ete6g8", width: 1080, height: 1080, social: 'Instagram Ads', title: 'Delicious wedding catering services for your special day in NYC', date: '12.10.2023', img: 'g3.jpg', ai: false },
        { id: "Yizrl9N_eDA", width: 1080, height: 1080, social: 'Instagram Ads', title: 'Delicious wedding catering services for your special day in NYC', date: '12.10.2023', img: 'g4.jpg', ai: false },
        { id: "RkBTPqPEGDo", width: 1080, height: 1920, social: 'Instagram Stories', title: 'Delicious wedding catering services for your special day in NYC', date: '12.10.2023', img: 'g5.jpg', ai: false },
        { id: "Yizrl9N_eDA", width: 1080, height: 1080, social: 'Instagram Ads', title: 'Delicious wedding catering services for your special day in NYC', date: '12.10.2023', img: 'g6.jpg', ai: false },
        { id: "KG3TyFi0iTU", width: 1080, height: 1080, social: 'Instagram Ads', title: 'Delicious wedding catering services for your special day in NYC', date: '12.10.2023', img: 'g7.jpg', ai: false },
    ];

    const { isLoading: isLoading, error: error, data: creativeData } = useQuery(['creativeData', activeUserId, activePage, selectedSort, total], () => fetchUserData(activeUserId, activePage, selectedSort, total));
    const { isLoading: creativeStatLoading, error: statError, data: creativeStatData } = useQuery(['creativeStatData', activeUserId], () => fetchStatData(activeUserId));
    const [showBlock, setShowBlock] = useState(true);
    useEffect(() => {
        if (isLoading) {
            setUnsplashPhotos(unsplashLoading);
            setShowBlock(true);
        }
        if (!isLoading && !error && creativeData && creativeData.items) {

            const data = creativeData.items
                .filter(image => !image.isDeleted)
                .map(image => {
                    if (!image.creativeImages || !image.creativeImages[0]) {
                        return null; // Пропускаем, если нет creativeImages
                    }
                    const inputDateString = image.createdAt;
                    const inputDate = new Date(inputDateString);
                    const formattedDate = `${inputDate.getDate()}.${(inputDate.getMonth() + 1).toString().padStart(2, '0')}.${inputDate.getFullYear()}`;

                    let platform = '';
                    let format = '';

                    if (image.creativeImages?.[0]?.creativeTheme) {
                        platform = image.creativeImages[0].creativeTheme?.creativeFormat?.platform || '';
                        format = image.creativeImages[0].creativeTheme?.creativeFormat?.format || '';
                    }
                    else {
                        return
                    }

                    return {
                        src: image.creativeImages[0].url,
                        width: image.creativeImages[0].width,
                        height: image.creativeImages[0].height,
                        social: `${platform} ${format}`,
                        title: image.data.title,
                        date: formattedDate,
                        ai: image.isAIGenerated
                    };
                })
                .filter(image => image !== null)
                .filter(image => image !== undefined)
            let countAiTrue = 0;

            data.forEach(item => {
                if (item?.ai === true) {
                    countAiTrue++;
                }
            });
            setAiGen(countAiTrue)

            setTotal(data.length);
            setCustom(data.length - countAiTrue)
            setUnsplashPhotos(data);
        }
    }, [isLoading, error, creativeData]);


    // useEffect(() => {
    //     if (!creativeStatLoading && !statError && creativeStatData) {
    //         setTotal(creativeStatData.total);
    //         if (creativeStatData.total == 0) {
    //             setShowBlock(false);
    //         } else {
    //             setShowBlock(true);
    //         }
    //         if (creativeStatData.total == 0) {
    //             setUnsplashPhotos([]);
    //         }
    //         setCustom(creativeStatData.custom)
    //         // setAiGen(creativeStatData.aIGenerated)
    //     }
    // }, [creativeStatLoading, statError, creativeStatData]);


    const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];


    const photos = unsplashPhotos.map((photo) => ({
        src: photo.src,
        width: photo.width,
        height: photo.height,
        social: photo.social,
        title: photo.title,
        date: photo.date,
        img: photo.src,
        ai: photo.ai,
        srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.src,
                width: breakpoint,
                height,
            };
        }),
    }));


    const renderPhoto = ({ layout, layoutOptions, imageProps: { alt, style, ...restImageProps }, social, width, height, title, date, img, ai }) => (
        <div
            className="creatives-item" onClick={() => handleOpen(img)}>
            <div className="creatives-item__img">
                {isLoading ? (
                    <Skeleton variant="rounded" style={{ ...style, width: "100%", padding: 0 }} />
                ) : (
                    <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
                )}

                {ai ? <div className="creatives-item__ai">
                    <span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1255_24490)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.61659 1.08422L0.851074 5.19206C0.362403 5.72515 0.339597 6.53629 0.797542 7.096L7.24093 14.9713C7.67741 15.5047 8.49309 15.5047 8.92956 14.9713L15.373 7.096C15.8309 6.53629 15.8081 5.72515 15.3194 5.19206L11.5539 1.08422C11.3473 0.85881 11.0555 0.730469 10.7497 0.730469H5.42075C5.11497 0.730469 4.82322 0.85881 4.61659 1.08422ZM9.32229 2.84017C9.20066 3.61384 8.95587 4.75426 8.5286 5.18389C8.08243 5.63241 6.84492 5.90139 6.06351 6.03154C5.97752 6.04586 5.978 6.20775 6.06403 6.22187C6.8329 6.34812 8.04062 6.60935 8.48683 7.05314C8.9451 7.50901 9.22152 8.77597 9.35193 9.54383C9.36638 9.62893 9.52055 9.62912 9.5352 9.54406C9.66754 8.77553 9.9467 7.5063 10.4027 7.04769C10.8421 6.60596 12.0204 6.33935 12.7834 6.20771C12.8695 6.19285 12.8684 6.02817 12.782 6.01463C12.0004 5.89205 10.7803 5.63704 10.3355 5.19454C9.90511 4.76637 9.652 3.61715 9.52499 2.8395C9.51076 2.75238 9.336 2.75297 9.32229 2.84017Z" fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1255_24490">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                        AI generated</span>

                </div> : null}
            </div>

            <div
            >
                <div className="creatives-item-info">
                    <div className="creatives-item-top">
                        {isLoading ? (
                            <Skeleton variant="rounded" width={"100rem"} height={"15rem"} />
                        ) : (
                            <div className="creatives-item__social">
                                {social}
                            </div>
                        )}
                        {isLoading ? (
                            <Skeleton variant="rounded" width={"80rem"} height={"15rem"} />
                        ) : (
                            <div className="creatives-item__size">
                                {width}x{height}
                            </div>
                        )}
                    </div>
                    {isLoading ? (
                        <Skeleton variant="rounded" style={{ marginBottom: '16rem' }} width="100%" height={"42rem"} />
                    ) : (
                        <div className="creatives-item__title">
                            {title}
                        </div>
                    )}

                    {isLoading ? (
                        <Skeleton variant="rounded" style={{ marginBottom: '24rem' }} width="100%" height={15} />
                    ) : (
                        <div className="creatives-item__date">
                            Creation date:  {date}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
    return (
        <div className="creatives">
            <div className="creatives-block">
                <div className="creatives-block-item">
                    <span>Total creatives</span>
                    {isLoading ? (
                        <Skeleton variant="rounded" width={"50rem"} height={"26rem"} />
                    ) : (
                        <strong>{total}</strong>
                    )}

                </div>
                <div className="creatives-block-item">
                    <span>AI generated</span>
                    {isLoading ? (
                        <Skeleton variant="rounded" width={"50rem"} height={"26rem"} />
                    ) : (
                        <strong>{aiGen}</strong>
                    )}

                </div>
                <div className="creatives-block-item">
                    <span>Custom</span>
                    {isLoading ? (
                        <Skeleton variant="rounded" width={"50rem"} height={"26rem"} />
                    ) : (
                        <strong>{custom}</strong>
                    )}

                </div>
            </div>

            <div className="table">
                <div className="table-top">
                    <div className="sidebars-tab__title">Creatives</div>

                    <div className="table-top-right">
                        <SelectCustom sorts={sorts} onSelectChange={handleSelectChange} defaultValue={defaultValue} />
                        {/* <div className="table-top-grid">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.814 2.35547C14.8485 2.35547 15.8138 2.54187 16.8162 3.078C17.7214 3.56209 18.4365 4.27718 18.9206 5.18236C19.4567 6.18485 19.6431 7.15009 19.6431 9.18463V12.812C19.6431 14.8466 19.4567 15.8118 18.9206 16.8143C18.4365 17.7195 17.7214 18.4346 16.8162 18.9187C15.8138 19.4548 14.8485 19.6412 12.814 19.6412H9.18658C7.15204 19.6412 6.1868 19.4548 5.18431 18.9187C4.27914 18.4346 3.56405 17.7195 3.07995 16.8143C2.54382 15.8118 2.35742 14.8466 2.35742 12.812V9.18463C2.35742 7.15009 2.54382 6.18485 3.07995 5.18236C3.56405 4.27718 4.27914 3.56209 5.18431 3.078C6.1868 2.54187 7.15204 2.35547 9.18658 2.35547H12.814ZM14.1267 12.1312L10.8251 16.2072C10.5493 16.5477 10.0482 16.5967 9.71156 16.3162L7.90564 14.8113L5.47139 17.246C5.61351 17.3514 5.76491 17.4471 5.9254 17.5329C6.66911 17.9307 7.38927 18.0698 9.18658 18.0698H12.814C14.6113 18.0698 15.3315 17.9307 16.0752 17.5329C16.7065 17.1953 17.1973 16.7045 17.5349 16.0732C17.6503 15.8574 17.744 15.6435 17.8187 15.4048L14.1267 12.1312ZM12.814 3.9269H9.18658C7.38927 3.9269 6.66911 4.06597 5.9254 4.46371C5.29407 4.80134 4.8033 5.29212 4.46566 5.92345C4.06792 6.66715 3.92885 7.38732 3.92885 9.18463V12.812C3.92885 14.5853 4.06422 15.31 4.44982 16.0433L7.30184 13.1927C7.58919 12.9054 8.04824 12.8846 8.36042 13.1447L10.1055 14.599L13.4227 10.5038C13.7045 10.1559 14.2195 10.1134 14.5545 10.4104L18.0628 13.5206C18.0689 13.3043 18.0717 13.0692 18.0717 12.812V9.18463C18.0717 7.38732 17.9326 6.66715 17.5349 5.92345C17.1973 5.29212 16.7065 4.80134 16.0752 4.46371C15.3315 4.06597 14.6113 3.9269 12.814 3.9269ZM8.25028 7.06975C8.90119 7.06975 9.42885 7.59742 9.42885 8.24833C9.42885 8.89923 8.90119 9.4269 8.25028 9.4269C7.59937 9.4269 7.07171 8.89923 7.07171 8.24833C7.07171 7.59742 7.59937 7.06975 8.25028 7.06975Z" fill="white" />
                            </svg>

                        </div>
                        <div className="table-top-list">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.53516 10.9983C3.53516 11.6505 4.06158 12.1769 4.71373 12.1769C5.36587 12.1769 5.8923 11.6505 5.8923 10.9983C5.8923 10.3462 5.36587 9.81975 4.71373 9.81975C4.06158 9.81975 3.53516 10.3462 3.53516 10.9983ZM3.53516 6.28404C3.53516 6.93618 4.06158 7.46261 4.71373 7.46261C5.36587 7.46261 5.8923 6.93618 5.8923 6.28404C5.8923 5.6319 5.36587 5.10547 4.71373 5.10547C4.06158 5.10547 3.53516 5.6319 3.53516 6.28404ZM3.53516 15.7126C3.53516 16.3595 4.06684 16.8912 4.71373 16.8912C5.36061 16.8912 5.8923 16.3595 5.8923 15.7126C5.8923 15.0657 5.36947 14.534 4.71373 14.534C4.05798 14.534 3.53516 15.0657 3.53516 15.7126ZM19.6423 15.7126C19.6423 15.2787 19.2905 14.9269 18.8566 14.9269H7.85659C7.42265 14.9269 7.07087 15.2787 7.07087 15.7126C7.07087 16.1465 7.42265 16.4983 7.85659 16.4983H18.8566C19.2905 16.4983 19.6423 16.1465 19.6423 15.7126ZM19.6423 10.9983C19.6423 10.5644 19.2905 10.2126 18.8566 10.2126H7.85659C7.42265 10.2126 7.07087 10.5644 7.07087 10.9983C7.07087 11.4323 7.42265 11.784 7.85659 11.784H18.8566C19.2905 11.784 19.6423 11.4323 19.6423 10.9983ZM7.85659 7.06975H18.8566C19.2905 7.06975 19.6423 6.71798 19.6423 6.28404C19.6423 5.8501 19.2905 5.49833 18.8566 5.49833H7.85659C7.42265 5.49833 7.07087 5.8501 7.07087 6.28404C7.07087 6.71798 7.42265 7.06975 7.85659 7.06975Z" fill="black" />
                            </svg>
                        </div> */}



                    </div>

                </div>
                {showBlock ? (
                    <div className='creatives-album'>
                        <PhotoAlbum
                            layout="columns"
                            columns={4}
                            photos={photos}
                            spacing={10}
                            width='10%'
                            // renderContainer={renderContainer}
                            // renderRowContainer={renderRowContainer}
                            renderPhoto={(props) => renderPhoto({ ...props, social: props.photo.social, width: props.photo.width, height: props.photo.height, title: props.photo.title, date: props.photo.date, img: props.photo.img, ai: props.photo.ai })}
                        />
                    </div>
                ) : (
                    <div className="empty">
                        <span>No data found</span>
                    </div>
                )}
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                    className="modal"
                >
                    <Box sx={style} className="modal-container">
                        <div className="creatives-modal-close" onClick={handleClose}>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 10L26 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                                <path d="M26 10L10 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                            </svg>

                        </div>
                        <div className="creatives-modal__img">
                            <img src={`${imgModal}`} alt="" />
                        </div>
                    </Box>
                </Modal>
            </div>


        </div>




    );
}

export default Creatives;